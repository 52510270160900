<template>
	<div class="p-message">
		<div class="p-message-head">
			<div :class="['p-message-item',select==item.id?'p-message-item colorselcet':'']" v-for="(item,index) in messagechoose"
			 :key="index" @click="getmessege(item.id)">
				{{item.name}}
			</div>
		</div>
		<div class="p-message-content" v-if="select==1||select==2">
			<div class="p-message-content-item" v-for="(item,index) in message" :key="index">
				<span>{{item.content}}</span><span>{{item.addtime}}</span>
				<div @click="todetail()" v-if="select==2" class="p-message-go">立即前往 >></div>
			</div>
			<div class="p-message-more">
				暂没更多
			</div>
		</div>
		<div class="p-message-contact" v-if="select==3">
			<div class="p-m-l">
				<div :class="['p-m-l-item',choose==index?'p-m-l-item p-m-l-item-l':'']" v-for="(item,index) in message" :key="index"
				 @click="thechoose(index)">
					<img :src="item.school_logo" />
					<div>
						<div class="p-ml-t">
							<div>{{item.school_name}}</div>
							<div class="p-ml-r">{{item.max_addtime}}</div>
						</div>
						<div class="p-ml-text">{{item.msg_list[0].content}}</div>
					</div>
				</div>
			</div>
			<div class="p-m-r-t">
				<div class="p-m-r">
					<div v-for="(item,indexa) in message[choose].msg_list" :key="indexa">
						<div class="p-m-t">{{item.addtime}}</div>
						<div class="p-m-r-left" v-if="item.type==3">
							<img :src="message[1].school_logo" />
							<div class="p-m-r-content">
								<div class="p-text-span">{{item.content}}</div>
							</div>
						</div>
						<div class="p-m-r-right" v-if="item.type==4">
							<div>

							</div>
							<div style="display: flex;flex-wrap: wrap;align-items: flex-start;">
								<div class="p-m-r-right-div">
									<div class="p-text-span">{{item.content}}</div>
								</div>
								<img :src="message[0].school_logo" />
							</div>
						</div>
					</div>
				</div>
				<!-- 				<div class="send">
					<textarea class="send"></textarea>
					<div class="sendbottom">
						
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				select: 2,
				message: [],
				choose: 0,
				messagechoose: [{
						name: "系统通知",
						id: 1,
					},
					{
						name: "课程消息",
						id: 2,
					},
					{
						name: "私信",
						id: 3,
					},
				],
			};
		},
		created() {
			this.getmessege(1)
		},
		methods: {
			//课程详情
			todetail(e) {
				this.$router.push({
					path: '/courseDetail',
					query: {
						id: e
					}
				})
			},
			thechoose(e) {
				this.choose = e
			},
			getmessege(e) {
				this.select = e
				this.$get("user/getstationmsg", {
					type: e
				}).then(response => {
					if (response.error == 0) {
						if (e == 3) {
							if (!response.data.msgWindow) {
								this.message = [{
										avatarurl: "",
										max_addtime: 1612249601,
										msg_list: [{
											addtime: "2021-02-02 15:06",
											content: "空",
											id: 16,
											status: 1,
											type: 4,
											type_id: 1,
											user_id: 25,
										}],
										school_logo: "https://thirdwx.qlogo.cn/mmopen/vi_32/glMH9jUOFSibpA7AlGf4Iwc5FlxYSbcMXFP6c7bAxLEvGyvrn0vsKeSLS2FPgSDTeYtdpvPRZ1qOQJhhSwFqnVw/132",
										school_name: "暂没机构",
										type_id: 1,
									},
									{
										avatarurl: "",
										max_addtime: 1612249601,
										msg_list: [{
											addtime: "2021-02-02 15:06",
											content: "空",
											id: 16,
											status: 1,
											type: 4,
											type_id: 1,
											user_id: 25,
										}],
										school_logo: "https://thirdwx.qlogo.cn/mmopen/vi_32/glMH9jUOFSibpA7AlGf4Iwc5FlxYSbcMXFP6c7bAxLEvGyvrn0vsKeSLS2FPgSDTeYtdpvPRZ1qOQJhhSwFqnVw/132",
										school_name: "暂没机构",
										type_id: 1,
									}
								]
								return
							}
							this.message = response.data.msgWindow
							this.message[0].msg_list =response.data.msgWindow[0].msg_list.reverse()
							this.message[1].msg_list =response.data.msgWindow[1].msg_list.reverse()
							return
						}
						this.message = response.data.stationMsgList.data
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
		}
	};
</script>


<style>
	.p-message {
		width: 63%;
		margin: 0 auto;
		min-height: 488px;
	}

	.p-message-head {
		height: 54px;
		width: 100%;
		position: relative;
		background: #FFFFFF;
		top: 30px;
		border-bottom: 1px solid #E5E5E5;
		;
	}

	.p-message-item {
		width: 150px;
		height: 54px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 54px;
		text-align: center;
		cursor: pointer;
		float: left;
	}

	.colorselcet {
		background: #00D0B0;
		color: #FFFFFF;
	}

	.p-message-content {
		width: 100%;
		height: auto;
		position: relative;
		background: #FFFFFF;
		top: 30px;
		margin-bottom: 111px;
	}

	.p-message-content-item {
		height: 138px;
		width: 100%;
		border-bottom: 1px solid #E5E5E5;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333 !important;
		line-height: 37px;
	}

	.p-message-content-item span {
		margin-left: 20px;
	}

	.p-message-content-item :nth-child(2) {
		float: right;
		margin-right: 21px;
		color: #999999;
	}

	.p-message-more {
		line-height: 89px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		text-align: center;
	}

	.p-message-go {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		line-height: 30px;
		margin-left: 20px;
	}

	/* 聊天记录的页面 */
	.p-message-contact {
		width: 100%;
		height: auto;
		top: 30px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-bottom: 103px;
	}

	.p-m-l {
		width: 346px !important;
		height: auto;
		background: #ECEAE8;
	}

	.p-m-l-item {
		width: 346px;
		height: 88px !important;
		background: #ECEAE8;
		border-bottom: 1px solid #D3D3D3;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		cursor: pointer;
	}

	.p-m-l-item-l {
		background: #FFFFFF !important;
	}

	.p-m-l-item img {
		width: 60px;
		height: 60px;
		border-radius: 15px;
		margin-left: 20px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.p-m-r {
		width: 840px;
		height: 513px;
		overflow-y: scroll;
		background: #F1F0F0;
	}

	.p-m-r-t {
		width: 840px;
		height: 600px;
		background: #F1F0F0;
	}

	.send {
		width: 840px;
		height: 120px;
		background: #FFFFFF;
		outline: none !important;
		border: none;
		resize: none;
		cursor: pointer;
	}

	.sendbottom {}

	.p-ml-t {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		display: flex;
		width: 250px;
		height: 20px;
		justify-content: space-around;
		align-items: space-around;
		margin-top: 21px;
		margin-left: 15px;
	}
	.p-ml-t :nth-child(1){	
		width: 160px !important;
		height: 20px;
		overflow: hidden;
		white-space: nowrap;
		/*内容超宽后禁止换行显示*/
		text-overflow: ellipsis;
	}
	.p-ml-text {
		margin-left: 15px;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-top: 11px;
		width: 220px;
		overflow: hidden;
		white-space: nowrap;
		/*内容超宽后禁止换行显示*/
		text-overflow: ellipsis;
		/*文字超出部分以菌棍距省略号显示*/
	}

	.p-ml-r {
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-left: 64px;
	}

	.p-m-r-left {
		height: auto;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-top: 33px;
		align-items: flex-start;
	}

	.p-m-r-left img {
		width: 54px;
		height: 54px;
		border-radius: 15px;
		margin-left: 20px;
	}

	.p-m-r-left div {
		margin-left: 10px;
		font-size: 14px;
		color: #333333;
		background: #FFFFFF;
		line-height: 18px;
		border-radius: 10px;
	}

	.p-m-r-right {
		height: auto;
		width: 100%;
		display: flex;
		align-items: flex-start;
		margin-top: 33px;
		justify-content: flex-end;
	}

	.p-m-r-right img {
		width: 54px;
		height: 54px;
		border-radius: 15px;
		margin-left: 10px;
	}

	.p-m-r-right-div {
		margin-left: 10px;
		font-size: 14px;
		color: #333333;
		line-height: 18px;
		max-width: 700px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.p-text-span {
		margin: 20px;
		
	}
	.p-m-t{
		width: 100%;
		height: 20px;
		font-size: 10px;
		color: #999999;
		text-align: center;
		margin-top: 30px;
	}
</style>
