<template>
	<div>
		<img class="p-vip-image" src="../../../assets/img/vip/vip-bg.png" />
		<div class="p-vip-user-head">
			<img class="p-vip-logo" v-if="useinfo.avatarurl" :src="useinfo.avatarurl" />
			<img class="p-vip-logo" v-if="!useinfo.avatarurl" src="../../../assets/img/home/unlogin.png" />
			<div class="p-vip-user">
				<div class="p-vip-user-abot"><span style="float: left;">{{useinfo.nickname}}</span>
					<div class="p-vip-know p-vip-color-unsure" v-if="useinfo.is_vip==0">未开通</div>
					<div class="p-vip-know p-vip-color-sure" v-if="useinfo.is_vip==1">已开通</div>
				</div>
				<div v-if="useinfo.is_vip==1" class="p-vip-time">将于{{useinfo.vip_time}}过期</div>
			</div>
		</div>
		<div class="p-vip-white">
			<div class="p-vip-count">
				<div v-for="(item,index) in vipdata" :key="index" :class="['p-vip-item-unchoose', choose==item.id ? 'p-vip-item-unchoose p-vip-item':'']"
				 @click="chooosewhich(index,item.id)">
					<div class="p-give-month">送{{item.give_month}}个月</div>
					<div class="p-vip-moeny"> ¥{{item.price}}<span>¥{{item.original_price}}</span></div>
					<div class="p-vip-month">{{item.month}}个月</div>
				</div>
			</div>
			<div class="p-vip-button" @click="pay(choose)">
				￥{{price}} 立即续费
			</div>
		</div>
		<el-card class="box-card">
			<div class="p-box-card-title">
				VIP尊享特权
			</div>
			<div style="display: flex;flex-flow: row wrap;align-content: flex-start;">
				<div v-for="(item,index) in aboutvip" :key="index" :class="['p-vip-box', item.ischoose==2 ? 'p-vip-box p-vip-box-c':'']">
					<img src="../../../assets/img/vip/vip-video.png" class="p-vip-video" />
					<div>{{item.name}}</div>
					<div>{{item.info}}</div>
				</div>
			</div>
		</el-card>
		<el-card class="p-vip-card-all">
			<div class="mb20">
				VIP尊享特权
			</div>
			<div class="p-vip-video-box-item">
				<div class="p-vip-video-box" v-for="(item,index) in goodsdata" :key="index" @click="todetail(item.goods_id)">
					<div class="p-vip-video-image-box"><img :src="item.goods_img" class="p-vip-video-image-box" /></div>
					<div>{{item.goods_name}}</div>
					<div>￥{{item.goods_price}}<span class="p-vip-prace-span">vip免费</span></div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data() {
			return {
				zt: 1,
				choose: "",
				price: 366,
				useinfo: {},
				vipdata: [],
				goodsdata: [],
				aboutvip: [{
						name: '尊享海量课程',
						info: '成为VIP会员后可观看平台内所有课程',
						ischoose: 1

					},
					{
						name: "敬请期待",
						info: "更多VIP福利敬请期待",
						ischoose: 2
					}
				],
				img: '',
				name: ''
			};
		},
		created() {
			this.getdata()
			this.getvip()
		},
		watch: {
			'$route': function() {
				this.getdata()
				this.getvip()
			},
		},
		methods: {
			chooosewhich(index, e) {
				this.choose = e
				this.price = this.vipdata[index].price
			},
			todetail(e) {
				if (!localStorage.getItem('token')) {
					this.$refs.child.toshow(1);
					return
				}
				this.$router.push({
					path: '/courseDetail',
					query: {
						id: e
					}
				})
			},
			pay(e) {
				if (!e) {
					this.$message.error("请选择套餐")
					return
				}
				this.$post("member/recharge/", {
					id: e,
					type: 1,
				}).then(response => {
					if (response.error == 0) {
						this.$router.push({
							path: '/payWay',
							query: {
								data: {
									total_money: response.data.total_money,
									order_sn: response.data.order_sn,
									pay_id: response.data.pay_id,
									payment: 1
								}
							}
						})
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getvip() {
				this.$get("user/getuserinfo", {}).then(response => {
					if (response.error == 0) {
						this.useinfo = response.data.userInfo
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getdata() {
				this.$get("member/viplist", {
					page: 1
				}).then(response => {
					if (response.error == 0) {
						this.vipdata = response.vip
						this.chooosewhich(0,this.vipdata[0].id)
						this.goodsdata = response.goodslist.data
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			}
		},
	};
</script>

<style>
	.p-vip-image {
		height: 148px;
		width: 100%;
		z-index: 200;
	}

	.p-vip-logo {
		width: 127px;
		float: left;
		height: 127px;
		border-radius: 10px;
	}

	.p-vip-user-head {
		position: relative;
		width: 70%;
		height: 127px;
		margin: 0 auto;
		margin-top: -98px;
	}

	.p-vip-color-unsure {
		background: #999999;
	}

	.p-vip-color-sure {
		background: #E0BD59;
	}

	.p-vip-user {
		float: left;
		margin-left: 21px;
		font-size: 20px;
		font-weight: bold;
	}

	.p-vip-time {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #B57F47;
		margin-left: 7px;
		margin-top: 19px;
	}

	.p-vip-user-abot {
		height: 20px;
		width: 100%;
		margin-top: 6px;
	}

	.p-vip-know {
		width: 62px;
		height: 20px;
		border-radius: 10px 0px 10px 0px;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-top: 3px;
		color: #FFFFFF;
		margin-left: 7px;
		text-align: center;
		line-height: 20px;
		float: left;
	}

	.p-vip-white {
		width: 100%;
		height: 459px;
		background: #FFFFFF;
		margin-top: -30px;
	}

	.p-vip-count {
		height: 142px;
		width: 100%;
		margin: 0 auto;
		padding-top: 123px;
		display: flex;
		justify-content: center;
	}

	.p-vip-item {
		background: #FBF0E0;
		border: 2px solid #EDD3AE !important;
	}

	.p-vip-item-unchoose {
		width: 280px;
		height: 142px;
		border: 2px solid #E6E6E6;
		border-radius: 8px;
		margin-left: 52px;
		cursor: pointer;
	}

	.p-vip-moeny {
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #E7B881;
		margin-left: 40px;
		margin-top: 38px;
	}

	.p-vip-moeny span {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-left: 13px;
		text-decoration: line-through;
		color: #CCCCCC;

	}

	.p-vip-month {
		margin-top: 29px;
		margin-left: 40px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;

	}

	.p-vip-button {
		width: 512px;
		height: 76px;
		background: linear-gradient(90deg, #F5E4CC 0%, #F4D19D 100%);
		border-radius: 10px;
		margin: 0 auto;
		margin-top: 63px;
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #854F17;
		cursor: pointer;
		text-align: center;
		line-height: 76px;

	}

	.box-card {
		position: relative;
		width: 68%;
		top: 20px;
		height: auto;
		margin: 0 auto;
		color: #181818;
		margin-bottom: 20px;
	}

	.p-box-card-title {
		margin-left: 20px;
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
	}

	.p-vip-box {
		width: 220px;
		height: 230px;
		background: linear-gradient(139deg, #F5E4CC 0%, #F4D19D 100%);
		border-radius: 10px;
		margin-top: 20px;
		overflow: hidden;
		margin-left: 34px;
		cursor: pointer;
	}

	.p-vip-box-c {
		background: #EAEAEA;
		border: none
	}

	.p-vip-box :nth-child(2) {
		font-size: 18px;
		width: 100%;
		margin-top: 27px;
		margin-bottom: 20px;
		text-align: center;
		color: #854F17;
	}

	.p-vip-box :nth-child(3) {
		width: 80%;
		text-align: center;
		font-size: 14px;
		margin-left: 10%;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #B57F47;
	}

	.p-vip-video {
		margin-top: 30px;
		position: relative;
		left: 75px;
		width: 70px;
		height: 49px;
	}

	.p-vip-wait {
		width: 220px;
		height: 230px;
		background: #EAEAEA;
		border-radius: 10px;
	}

	.p-vip-card-all {
		background: #FFFFFF;
		width: 68%;
		position: relative;
		margin: 0 auto;
		top: 21px;
	}

	.p-vip-card-all div :nth-child(1) {
		margin-left: 20px;
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
	}

	.p-vip-video-box-item {
		width: 100%;
		height: auto;
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
	}

	.p-vip-video-box {
		width: 270px;
		height: 316px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		margin-bottom: 21px;
		border-radius: 5px;
		margin-left: 21px;
		margin-right: 15px;
	}
	.p-vip-video-box :hover img{
		transform: scale(1.4) !important;
		transform: scale3d(1.2, 1.2, 1.2) !important;
		-ms-transform: scale3d(1.2, 1.2, 1.2) !important;
		-moz-transform: scale3d(1.2, 1.2, 1.2) !important;
		-webkit-transform: scale3d(1.2, 1.2, 1.2) !important;
		-o-transform: scale3d(1.2, 1.2, 1.2) !important;
	}
	.p-vip-video-image-box {
		width: 100%;
		position: relative;
		left: -20px;
		height: 200px;
		border-radius: 5px 5px 0px 0px;
	}

	.p-vip-video-box :nth-child(2) {
		font-size: 16px;
		width: 92%;
		margin-left: 4%;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-top: 14px;
		overflow: hidden;
		white-space: nowrap;
		/*内容超宽后禁止换行显示*/
		text-overflow: ellipsis;
		/*文字超出部分以菌棍距省略号显示*/
		line-height: 24px;
	}

	.p-vip-video-box :nth-child(3) {
		width: 92%;
		margin-left: 4%;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-top: 14px;
		line-height: 24px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		font-size: 14px;
		color: #F83850;
	}

	.p-vip-prace-span {
		width: 58px;
		height: 18px;
		background: #E0BD59;
		border-radius: 3px;
		text-align: center;
		font-size: 12px !important;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 18px;
	}

	.mb20 {
		margin-bottom: 20px;
	}

	.p-give-month {
		width: 62px;
		height: 26px;
		background: #FA4140;
		border-radius: 8px;
		text-align: center;
		line-height: 26px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: bold;
		color: #FFFFFF;
		position: absolute;
		margin-left: 218.5px;
		margin-top: -0.5px;
	}
</style>
